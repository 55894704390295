<template>
  <div class="jue-cell">
    <van-field
      :label="label"
      :required="required"
      @click="switch_popup = true"
      clickable
      is-link
      placeholder="点击选择"
      readonly="readonly"
      v-model="resultDate"
    />
    <van-calendar
      v-model="switch_popup"
      :show-confirm="false"
      @close="switch_popup = false"
      @confirm="confirm"
      color="#409EFF"
      type="range"
    />
  </div>
</template>
<script>
export default {
  model: {
    prop: 'selectValue'
  },
  props: {
    label: {
      type: [String]
    },
    placeholder: {
      type: [String]
    },
    isLink: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    selectValue: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // maxDate: new Date().getTime(),
      switch_popup: false,
      resultDate: '',
      currentDate: [new Date().getTime() - 3 * 24 * 60 * 60 * 1000, new Date().getTime()]
    }
  },
  mounted() {
    // if (this.selectValue) {
    //   // this.currentDate = new Date(this.selectValue).getTime()
    // } else {
    //   this.currentDate = [new Date().getTime(), new Date().getTime()]
    // }
  },
  methods: {
    formatDate(d) {
      // 获取当前日期
      var date = new Date(d)

      // 获取当前月份
      var nowMonth = date.getMonth() + 1

      // 获取当前是几号
      var strDate = date.getDate()

      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = '0' + nowMonth
      }

      // 对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }

      return `${date.getFullYear()}-${nowMonth}-${strDate}`
    },
    confirm(date) {
      this.switch_popup = false
      const [start, end] = date
      this.resultDate = `${this.formatDate(start)}/${this.formatDate(end)}`
      this.$emit('input', this.resultDate)
      this.$emit('change', this.resultDate)
    }
  },
  watch: {
    selectValue() {
      if(this.selectValue == ''){
        this.resultDate = this.selectValue
      }
    }
  }
}
</script>
<style lang="less" scoped>
.jue-cell {
  /deep/ .van-cell:after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0;
    bottom: 0;
    left: 16px;
    border-bottom: 1px solid #ebedf0;
    transform: scaleY(0.5);
  }
}
</style>